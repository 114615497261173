<template>
  <section id="education" class="overflow-hidden mt-5" data-aos="fade-up fade-down">
    <div> <base-heading class="text-uppercase primary--text" :tag="'h3'">
        Education
      </base-heading></div>
    <v-row class="education">
      <v-col cols="12">
        <v-timeline :dense="isMobile ? true : false">
          <v-timeline-item v-for="(education, index) in educations" :key="education.id" :small="isMobile ? true : false"
            :class="[
              {
                'text-right': (index + 1) % 2 === 0 && !isMobile
              },
            ]" fill-dot data-aos="fade-up">
            <template v-slot:opposite v-if="!isMobile">
              <span class="text-subtitle-2 font-weight-medium text-uppercase">{{ education.from }} - {{ education.to
              }}</span>
            </template>

            <v-card flat class="white--text font-weight-light my-5 my-md-8 education-cards" width="100%"
              color="transparent">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-1 primary--text">
                    {{ education.institution }}
                  </div>
                  <v-list-item-title class="font-weight-medium mb-1"
                    :class="isMobile ? 'custom-title-mobile' : 'custom-title-desk'">
                    {{ education.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <p v-if="education.diplom !== null" class="mb-1">
                      {{ education.diplom }}
                    </p>
                    <small v-html="education.description" v-if="education.description !== null"></small>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import educationData from "@/assets/data/education.json";
import isMobile from "../plugins/isMobile";
export default {
  name: "Education",

  data() {
    return {
      educations: educationData,
    };
  },

  computed: {
    isMobile() {
      return isMobile();
    },
  },
};
</script>

<style>
.education {
  display: flex;
  justify-content: center;
}

.education-cards {
  width: fit-content;
}

.v-timeline::before {
  top: 55px;
  height: calc(100% - 110px);
}

.custom-title-desk {
  font-size: 2vw;
}

.custom-title-mobile {
  font-size: 4vw;
}
</style>
